<template>
<div class="wallet-page">
  <div class="d-flex wallet-page-statics">
    <b-card
      no-body
      v-for="item in statisticsItems"
      :key="item.icon"
      :class="item.customClass"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col>
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }} TL
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
  <b-card no-body v-if="!hasIframe && !success">
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Bakiye yükle</b-card-title>
        <b-card-text class="text-muted mt-25">
          Lütfen yüklemek istediğiniz bakiyeyi giriniz
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <b-form-group>
          <div class="d-flex flex-wrap align-items-center justify-content-start my-1">
            <label
              for="cvv"
              class="text-nowrap mr-1 mb-1"
            >
              Bakiye giriniz:
            </label>
            <b-form-input
              v-model="amount"
              class="mr-1 mb-1"
              trim
            />
            <label
              for="tc"
              class="text-nowrap mr-1 mb-1"
            >
              Tc giriniz:
            </label>
            <b-form-input
              v-model="identityNumber"
              class="mr-1 mb-1"
              maxLength="11"
              trim
            />
            <label
              for="tax"
              class="text-nowrap mr-1 mb-1"
            >
              Vergi Numarası:
            </label>
            <b-form-input
              v-model="taxNumber"
              class="mr-1 mb-1"
              trim
            />
            <label
              for="taxOffice"
              class="text-nowrap mr-1 mb-1"
            >
              Vergi bürosu:
            </label>
            <b-form-input
              v-model="taxOffice"
              class="mr-1 mb-1"
              trim
            />
            <label
              for="companyName"
              class="text-nowrap mr-1 mb-1"
            >
              Şirket Ünvanı:
            </label>
            <b-form-input
              v-model="companyName"
              class="mr-1 mb-1"
              trim
            />
            <label
              for="mobilePhone"
              class="text-nowrap mr-1 mb-1"
            >
              Telefon Numarası:
            </label>
            <b-form-input
              v-model="mobilePhone"
              maxLength="11"
              class="mr-1 mb-1"
              trim
            />
            <b-button
              variant="primary"
              class="mb-1"
              @click="getIframe()"
            >
              İlerle
            </b-button>
          </div>
        </b-form-group>
      </b-card-body>
    </b-card>
  <b-card no-body v-if="hasIframe">
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Ödeme Yapınız</b-card-title>
        <b-card-text class="text-muted mt-25">
          Lütfen kart bilgilerinizi girerek ödeme yapınız
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <iframe class="iframe" :src="iframeUrl"/>
      </b-card-body>
  </b-card>
  <div v-if="success">
    <Finish />
  </div>
</div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
  BFormGroup, BFormRadio, BFormInput, BButton
} from 'bootstrap-vue'
import Finish from './Finish'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormGroup, BFormRadio, BFormInput,
    BButton,
    Finish,
    ToastificationContent
  },
  data() {
    return {
      hasIframe: false,
      iframeUrl: '',
      amount: '',
      identityNumber: '',
      taxNumber: '',
      taxOffice: '',
      companyName: '',
      mobilePhone: '',
    }
  },
  methods: {
    getIframe() {
      this.$http.post('/api/v1/wallet/payment', 
        {
          amount: this.amount,
          identityNumber: this.identityNumber,
          taxNumber: this.taxNumber,
          taxOffice: this.taxOffice,
          companyName: this.companyName,
          mobilePhone: '009' + this.mobilePhone,
        }
      ).then(res => { 
        this.hasIframe = true,
        this.iframeUrl = res.data.data.url
      })
    }
  },
  computed: {
    success(){
      return JSON.parse(this.$route.query.success || 'null')
    },
    statisticsItems() {
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: store.getters['wallet-store/wallet'].totalBalance,
          subtitle: 'Toplam Bakiye',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: store.getters['wallet-store/wallet'].lockedBalance,
          subtitle: 'Bloke Edilen Bakiye',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: store.getters['wallet-store/wallet'].availableBalance,
          subtitle: 'Kullanılabilir Bakiye',
          customClass: '',
        },
      ]
    }
  },
  mounted() {
    if(this.success === false){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ödemenizde bir problem oluştu, lütfen iletişime geçiniz',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
    }
    this.statisticsItems[0].title = store.getters['wallet-store/wallet'].availableBalance
    this.statisticsItems[1].title = store.getters['wallet-store/wallet'].lockedBalance
    this.statisticsItems[2].title = store.getters['wallet-store/wallet'].totalBalance
  }
}
</script>

<style lang="scss" scoped>
.statistics{
    column-gap: 10px;
    & > div {
        max-height: 90px;
    }
}
.iframe {
  width: 100%;
  min-height: 363px;
  border: 0px;
  overflow: hidden;
  & > ::v-deep .wrapper {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}
.wallet-page{
  &-statics {
    max-width: 100%;
    & > div {
      max-height: 90px;
      width: 33%;
    }
    & > div:not(:last-child){
      margin-right: 45px !important;
    }
  }
}
</style>
